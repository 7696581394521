var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('SpinnerLogo', {
    staticClass: "pt-5"
  }) : _vm.error ? _c('div') : _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v("Select a target and see the current progress towards it. Targets will also be shown elsewhere within Etainabl, such as in PDF reports.")];
      },
      proxy: true
    }])
  }, [[_vm._v("Dashboard")]], 2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Select a target",
      "options": _vm.targetOptions,
      "type": "select",
      "alt": ""
    },
    model: {
      value: _vm.selectedTargetId,
      callback: function ($$v) {
        _vm.selectedTargetId = $$v;
      },
      expression: "selectedTargetId"
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }